<template>
  <div class="works-container">
    <div class="conditions">
      <el-select v-model="currentPeriod" @change="changePeriod">
        <el-option
          v-for="item in periods"
          :key="item.id"
          :label="'第' + item.num + '期'"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="currentType"
        style="margin-left: 50px"
        @change="changeType"
      >
        <el-option
          v-for="item in typeOptions"
          :key="item.label"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button
        class="zip-btn"
        v-if="currentType === 1"
        type="primary"
        @click="downloadZip"
      >
        打包下载文件</el-button
      >
      <el-button
        style="margin-left: 40px"
        type="primary"
        @click="uploadVisible = true"
        >上传作品</el-button
      >
    </div>
    <el-table :data="list" stripe border fit>
      <el-table-column prop="id" label="序号"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="works_name" label="作品名称"></el-table-column>
      <el-table-column label="作品">
        <template #default="scope">
          <el-image
            v-if="scope.row.works_type === 0"
            :src="scope.row.works_url"
            style="width: 100px; height: 100px"
            fit="cover"
            :preview-src-list="imageList"
          ></el-image>
          <span v-else>
            <a
              :href="scope.row.works_url"
              style="color: #2c67d5; text-decoration: #2c67d5"
              target="_blank"
              >查看文件</a
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="联系方式"></el-table-column>
      <el-table-column prop="school" label="学校"></el-table-column>
      <el-table-column prop="Result.rank" label="排名" sortable>
        <template #default="scope">
          <div
            style="
              display: flex;
              width: 100%;
              flex-direction: column;
              align-items: center;
            "
          >
            <span>{{
              scope.row.Result?.rank ? scope.row.Result.rank : '无'
            }}</span>
            <div>
              <el-button
                type="text"
                v-if="scope.row.period_id === onlinePeriod"
                @click="toRank(scope.row)"
                >设置</el-button
              >
              <el-button type="text" @click="toDelete(scope.row)">
                删除
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="sn" label="作品识别码"></el-table-column>-->
    </el-table>
    <el-pagination
      :total="total"
      background
      layout="prev, pager, next"
      :current-page="currentPage"
      style="margin-top: 20px"
      @current-change="getNewData"
    ></el-pagination>
    <el-dialog v-model="rankVisible" :width="400" title="设置排名">
      <el-input v-model="newRank"></el-input>
      <el-button
        @click="setRank"
        style="margin: 20px auto 0"
        :loading="rankLoading"
        >确认</el-button
      >
    </el-dialog>
    <el-dialog v-model="uploadVisible" title="上传作品">
      <div class="upload-dialog">
        <el-form
          :model="uploadData"
          label-width="80px"
          :rules="rules"
          ref="uploadRef"
        >
          <el-form-item label="姓名" prop="name">
            <el-input v-model="uploadData.name" maxlength="15"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="uploadData.phone"></el-input>
          </el-form-item>
          <el-form-item label="学校" prop="school">
            <el-input v-model="uploadData.school"></el-input>
          </el-form-item>
          <el-form-item label="作品类型" prop="works_type">
            <el-select v-model="uploadData.works_type">
              <el-option
                v-for="item in typeOptions"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="作品名称" prop="works_name">
            <el-input v-model="uploadData.works_name"></el-input>
          </el-form-item>
          <el-form-item label="指导老师" prop="instructor">
            <el-input v-model="uploadData.instructor"></el-input>
          </el-form-item>
          <el-form-item label="创作说明" prop="creation_notes">
            <el-input
              v-model="uploadData.creation_notes"
              type="textarea"
              maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传" prop="works_url">
            <el-upload
              action="https://dz.gzsdzth.com/api/v1/works/upload"
              list-type="picture-card"
              :limit="1"
              :on-success="uploadSuccess"
              v-if="!uploadData.works_url"
              :before-upload="beforeUpload"
            >
              <el-icon><Plus /></el-icon>
            </el-upload>
            <div v-else>
              <img
                v-if="uploadData.works_type === 0"
                style="width: 200px; height: 200px"
                :src="uploadData.works_url"
                alt=""
              />
              <a v-else :href="uploadData.works_url">已上传作品</a>
              <span
                style="font-size: 16px; cursor: pointer; margin-left: 20px"
                @click="handleRemove"
                >删除</span
              >
            </div>
          </el-form-item>
        </el-form>
        <div style="align-self: end">
          <el-button @click="uploadVisible = false">取消</el-button>
          <el-button type="primary" @click="submit" :loading="uploadLoading"
            >提交</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import {
  worksList,
  periodList,
  createRank,
  delWork,
  zipFile,
  uploadWork
} from '@/api'
import { myError, mySuccess } from '@/hooks/useMessage'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Delete, Plus, ZoomIn } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'

const currentType = ref(0)
const typeOptions = [
  {
    label: '图片',
    value: 0
  },
  {
    label: '文件',
    value: 1
  }
]

const currentPeriod = ref(0)
const onlinePeriod = ref(0)
const periods = ref([])
onMounted(() => {
  periodList().then((res) => {
    console.log(res, '---')
    periods.value = res.data
    const current = res.data.find((item) => item.is_current)
    currentPeriod.value = onlinePeriod.value = current.id
    getData(1, current.id, currentType.value)
  })
})

const list = ref([])
const imageList = ref([])
const total = ref(0)
const currentPage = ref(1)

const getData = (page, period_id = 1, type) => {
  worksList(page, period_id, type).then((res) => {
    // console.log(res)
    list.value = res.data.rows
    total.value = res.data.count
    res.data.rows.forEach((item) => {
      if (item.works_type === 0) imageList.value.push(item.works_url)
    })
  })
}

const getNewData = () => {
  getData(currentPage.value, currentPeriod.value, currentType.value)
}

const changePeriod = (e) => {
  console.log(e)
  currentPage.value = 1
  getData(1, e, currentType.value)
}

const changeType = (e) => {
  currentPage.value = 1
  getData(1, currentPeriod.value, e)
}

const rankObj = ref({})
const rankVisible = ref(false)
const newRank = ref(1)
const rankLoading = ref(false)
const toRank = (row) => {
  rankVisible.value = true
  rankObj.value = row
}
const setRank = () => {
  const params = {
    id: rankObj.value.Result?.id,
    works_id: rankObj.value.id,
    works_type: rankObj.value.works_type,
    rank: newRank.value ? parseInt(newRank.value) : null,
    period_id: rankObj.value.period_id
  }
  console.log(params)
  rankLoading.value = true
  createRank(params)
    .then((res) => {
      if (res.code === 1000) {
        mySuccess()
        setTimeout(() => {
          getData(currentPage.value, currentPeriod.value, currentType.value)
        }, 200)
        rankVisible.value = false
      } else {
        myError()
      }
    })
    .catch((err) => {
      myError()
    })
    .finally(() => {
      rankLoading.value = false
    })
}

const toDelete = (row) => {
  let prompt = '确定删除该作品吗？'
  if (row.Result?.rank) {
    prompt = '该作品已经被排名，确定删除吗？'
  }
  ElMessageBox.confirm(prompt, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      delWork(row.id).then((res) => {
        if (res.code === 1000) {
          mySuccess()
          getData(currentPage.value, currentPeriod.value, currentType.value)
        } else {
          myError()
        }
      })
    })
    .catch(() => {})
  // delWork(row.id).then((res) => {
  //   console.log(res)
  // })
}

const downloadZip = () => {
  zipFile(currentPeriod.value)
    .then((res) => {
      console.log(res)
      if (res.code === 1000) {
        const url = res.data
        // 下载文件
        const a = document.createElement('a')
        a.href = url
        a.download = '作品.zip'
        a.click()
        // location.reload()
      }
    })
    .catch((err) => {
      myError('请刷新页面重试')
    })
}

// 上传作品
const uploadVisible = ref(false)
const uploadRef = ref()
const uploadLoading = ref(false)
const uploadData = reactive({
  name: '',
  phone: '',
  school: '',
  works_type: 0,
  works_name: '',
  works_url: '',
  instructor: '',
  creation_notes: ''
})
const rules = reactive({
  name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
  works_name: [{ required: true, message: '请填写作品名称', trigger: 'blur' }],
  works_url: [{ required: true, message: '请上传作品' }]
})
const handleRemove = () => {
  uploadData.works_url = ''
}
const uploadSuccess = (res) => {
  if (res.code === 200) {
    uploadData.works_url = res.data
  }
}
const beforeUpload = (file) => {
  const typeArray = [
    '.jpg',
    '.png',
    '.bmp',
    '.JPG',
    '.PNG',
    '.BMP',
    '.gif',
    '.GIF',
    '.txt',
    '.TXT',
    '.doc',
    '.DOC',
    '.docx',
    '.xls',
    '.xlsx',
    '.pdf',
    '.ppt',
    '.pptx'
  ]
  const type = file.name.substring(file.name.lastIndexOf('.'))
  const isImage = typeArray.indexOf(type) > -1
  if (!isImage) {
    myError('上传的文件格式不正确!')
    return false
  }

  //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
  const is20M = file.size / 1024 / 1024 < 20

  //限制文件上传大小
  if (!is20M) {
    myError('上传图片大小不能超过 20MB!')
    return false
  }

  return true
}
const submit = () => {
  uploadRef.value.validate((valid) => {
    if (valid) {
      uploadLoading.value = true
      uploadWork(uploadData)
        .then((res) => {
          if (res.code === 200) {
            uploadData.name = ''
            uploadData.phone = ''
            uploadData.works_type = 0
            uploadData.works_name = ''
            uploadData.school = ''
            uploadData.works_url = ''
            uploadData.instructor = ''
            uploadData.creation_notes = ''
            getNewData()
            mySuccess('保存成功')
          } else {
            myError('保存失败，请稍后再试')
          }
        })
        .catch(() => {})
        .finally(() => {
          uploadLoading.value = false
        })
    }
  })
}
</script>

<style scoped lang="scss">
.works-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .conditions {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
  }
  .zip-btn {
    margin-left: 100px;
  }
  .upload-dialog {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
